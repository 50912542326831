var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { attrs: { "data-test-id": "station" } },
    [
      _c("ui-tooltip", { attrs: { tooltip: _vm.tooltipText } }, [
        _vm._v(" " + _vm._s(_vm.stationCode) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
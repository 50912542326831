<script>
export default {
  name: 'TooltipHeader',
  props: {
    cell: {
      type: Object,
      default: () => ({}),
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div class="d-flex align-items-center">
    <p class="ellipsis">
      {{ cell.title }}
    </p>
    <ui-tooltip :tooltip="tooltip">
      <ui-icon
        :color="GRAYSCALE.inkLight"
        :size="ICONS_SIZES.small"
        :icon="ICONS.infoFull"
        class="ml-1"
      />
    </ui-tooltip>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { MuiModal } from '@emobg/motion-ui/v1';
import carRental from '../../store/CarrentalModuleMap';
import BulkActivation from './BulkActivation';
import { MODES, STEPS } from '../const/bulkProcess.const';
import { scopes } from '../store/DevicesModule';

export default {
  name: 'OemBulkActivationModal',
  components: {
    MuiModal,
    BulkActivation,
  },
  props: {
    isModalVisible: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      step: STEPS.bulkProcess,
      rowsProcessed: null,
      modalConfig: {
        title: '',
        header: {
          class: 'pl-3',
          isClosable: true,
        },
      },
    };
  },
  watch: {
    isModalVisible(newValue) {
      this.isOpen = newValue;
    },
    mode(value) {
      this.modalConfig.title = `Import list of OEMS devices to ${value}`;
    },
  },
  created() {
    this.STEPS = STEPS;
    this.MODES = MODES;
  },
  methods: {
    ...mapMutations(carRental.devices, [
      'resetState',
    ]),
    onBulkSuccess(rowsProcessed) {
      this.rowsProcessed = rowsProcessed;
      this.step = STEPS.processSuccess;
    },
    onModalClosed() {
      this.resetState({ scopes: [scopes.activate, scopes.deactivate] });
      if (this.step === STEPS.processSuccess) {
        this.$emit('bulkSuccess');
      } else {
        this.$emit('close');
      }
      this.step = STEPS.bulkProcess;
    },
  },
};
</script>

<template>
  <MuiModal
    v-model="isOpen"
    :size="SIZES.large"
    class="BulkActivationModal"
    data-test-id="oem_bulk_activation-modal"
    v-bind="modalConfig"
    @modal-closed="onModalClosed"
  >
    <div slot="body">
      <BulkActivation
        v-if="step === STEPS.bulkProcess"
        :mode="mode"
        data-test-id="bulk_activation"
        @success="onBulkSuccess"
      />
      <div
        v-else
        data-test-id="success_box"
        class="BulkActivationModal__successBox row no-gutters px-4 emobg-border-1 emobg-border-color-success emobg-background-color-ground-lightest"
      >
        <div class="col-6 d-flex flex-column align-items-center justify-content-center pr-2">
          <div class="d-flex align-items-start w-100 mb-5">
            <ui-icon
              :color="GRAYSCALE.success"
              :size="ICONS_SIZES.xLarge"
              :icon="ICONS.bold.check"
              class="mr-4"
            />
            <div class="d-flex flex-column">
              <p class="emobg-body-2 emobg-color-success mb-2">
                {{ `Your ${mode === MODES.activate ? 'activation' : 'deactivation'} OEM file was succesfully uploaded` }}
              </p>
              <div class="emobg-caption-1">
                {{ `${rowsProcessed} of ${rowsProcessed}` }} <span class="emobg-color-ink-light">devices were uploaded</span>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-start w-100 mt-5">
            <ui-icon
              :color="GRAYSCALE.inkLighter"
              :size="ICONS_SIZES.xLarge"
              :icon="ICONS.bold.check"
              class="mr-4"
            />
            <div class="d-flex flex-column">
              <p class="emobg-body-2 mb-3">
                {{ `Device ${mode === MODES.activate ? 'activation': 'deactivation'}` }}
              </p>
              <p class="emobg-caption-1">
                The activation process may take up to 72h.
              </p>
              <p class="emobg-caption-1 emobg-color-ink-light mb-4">
                Once you confirm, the activation process with the OEM will begin.
                When the activation process finished successfully the data status will change to ACTIVATED.
                However, if the vehicle is not eligible the activation process will be rejected and the data status will change to REJECTED.
              </p>
              <p
                :class="{ 'mb-4': mode === MODES.deactivate }"
                class="emobg-caption-1 emobg-color-ink-light"
              >
                If you get an ERROR status then a new request should be done.
                If by retrying the error persists, then it will need to be reported.
              </p>
              <p
                v-if="mode === MODES.deactivate"
                class="emobg-caption-1 emobg-color-ink-light"
              >
                Also, remember that you can activate OEM devices again.
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center col-6">
          <img
            class="w-100"
            src="../images/devices_uploaded.png"
            alt="devices uploaded"
          >
        </div>
      </div>
    </div>
    <div
      v-if="step === STEPS.processSuccess"
      slot="footer"
      class="p-3 d-flex justify-content-end"
    >
      <ui-button
        data-test-id="understood-button"
        @clickbutton="$emit('bulkSuccess')"
      >
        Understood
      </ui-button>
    </div>
  </MuiModal>
</template>

import get from 'lodash/get';
import find from 'lodash/find';
import { ALGOLIA_REFINEMENT_OPTIONS } from '@emobg/motion-ui/v1';
import { FALLBACK_MESSAGE } from '@emobg/web-utils';

import VehicleLink from '../../components/VehicleLink';
import TooltipHeader from '../../components/TooltipHeader';
import ReachabilityComponent from '../components/ReachabilityComponent';
import StationComponent from '../components/StationComponent';

import { HEADER_TOOLTIPS } from '../const/tooltips.const';

const contentCells = [
  {
    title: 'Reg. number',
    component: VehicleLink,
    width: 115,
    props: result => ({
      plateNumber: get(result, 'license_plate') || FALLBACK_MESSAGE.dash,
      vinNumber: result.objectID,
    }),
  },
  {
    title: 'Veh. type',
    property: 'vehicle_type',
    width: 100,
  },
  {
    title: 'Category',
    property: 'category',
    width: 100,
  },
  {
    title: 'Brand',
    property: 'brand',
    width: 100,
  },
  {
    title: 'Model',
    property: 'model',
    width: 110,
  },
  {
    title: 'Movement type',
    property: 'movement_type',
    width: 100,
  },
  {
    title: 'GWY status',
    property: 'gw_status',
    width: 100,
  },
  {
    title: 'Station',
    component: StationComponent,
    width: 100,
    props: result => ({ stationCode: get(result, 'station') || FALLBACK_MESSAGE.dash }),
  },
  {
    title: 'Connectivity',
    property: 'connectivity',
    width: 120,
  },
  {
    title: 'Data last received',
    component: ReachabilityComponent,
    width: 120,
    props: result => ({ lastReceivedDate: result.last_received_date }),
    headerComponent: TooltipHeader,
    headerProps: () => ({ tooltip: HEADER_TOOLTIPS.last_received_date }),
  },
];

const vehicleListFacets = (stations = []) => ([
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Vehicle type',
      attributeName: 'vehicle_type',
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Category',
      attributeName: 'category',
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Brand',
      attributeName: 'brand',
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Model',
      attributeName: 'model',
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Movement type',
      attributeName: 'movement_type',
      sortFacetsBy: ['count:desc', 'name:asc'],
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'GWY status',
      attributeName: 'gw_status',
      placeholder: 'Select gwy status',
      sortFacetsBy: ['count:desc', 'name:asc'],
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Station',
      attributeName: 'station',
      transformValue: stationCode => {
        const station = find(stations, { greenwayId: stationCode });
        return station ? `${station.greenwayId} - ${station.greenwayName}` : stationCode;
      },
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Connectivity',
      attributeName: 'connectivity',
      placeholder: 'Select OEM/Geotab/Invers',
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Data last received',
      attributeName: 'last_received_date',
    },
    customFilters: [
      {
        range: {
          start: null,
          end: 1,
        },
        label: '0 hrs - 24 hrs',
      },
      {
        range: {
          start: 1,
          end: 2,
        },
        label: '24 hrs - 48 hrs',
      },
      {
        range: {
          start: 2,
          end: 5,
        },
        label: '48 hrs - 5 days',
      },
      {
        range: {
          start: 5,
          end: 10,
        },
        label: '5 days - 10 days',
      },
      {
        range: {
          start: 10,
          end: null,
        },
        label: 'More than 10 days',
      },
      {
        range: {
          start: null,
          end: null,
        },
        label: 'Never received',
      },
    ],
  },
]);

const disjunctiveFacets = [
  'gw_status',
  'installation_status',
  'movement',
  'model',
  'category',
  'location',
];

const disjunctiveFacetsRefinements = {
  gw_status: ['ON', 'TC', 'RR'],
};

export {
  contentCells,
  disjunctiveFacets,
  disjunctiveFacetsRefinements,
  vehicleListFacets
};

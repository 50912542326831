import moment from 'moment-timezone';

export const getDateDiff = date => {
  const momentTimestamp = moment(date);
  const today = moment();
  const dateDiffDays = Math.abs(momentTimestamp.diff(today, 'days'));

  if (!dateDiffDays) {
    /**
     * Always round up to nearest hour and never show 0 hours
     */
    const dateDecimalHoursDiff = Math.abs(momentTimestamp.diff(moment(), 'hours', true));
    const hoursDiff = Math.trunc(dateDecimalHoursDiff) + 1;
    return `${hoursDiff} hours ago`;
  }
  return `${dateDiffDays} days ago`;
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "RouterLink",
    {
      staticClass: "emobg-link-primary emobg-body-2",
      attrs: {
        to: {
          name: _vm.carrental.vehicles.summary[_vm.summaryTab],
          params: { vehicleVin: _vm.vinNumber },
        },
        target: _vm.target,
        "data-test-id": "carrental-vehicle-link",
      },
    },
    [_vm._v(" " + _vm._s(_vm.plateNumber) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
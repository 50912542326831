<script>
import carrental from '../router/CarrentalRouterMap';

export default {
  name: 'VehicleLink',
  props: {
    plateNumber: {
      type: String,
      required: true,
    },
    vinNumber: {
      type: String,
      required: true,
    },
    summaryTab: {
      type: String,
      default: 'index',
    },
    target: {
      type: String,
      default: '_self',
    },
  },
  created() {
    this.carrental = carrental;
  },
};
</script>
<template>
  <RouterLink
    :to="{
      name: carrental.vehicles.summary[summaryTab],
      params: { vehicleVin: vinNumber }
    }"
    :target="target"
    class="emobg-link-primary emobg-body-2"
    data-test-id="carrental-vehicle-link"
  >
    {{ plateNumber }}
  </RouterLink>
</template>

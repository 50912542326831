var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center" },
    [
      _c("p", { staticClass: "ellipsis" }, [
        _vm._v(" " + _vm._s(_vm.cell.title) + " "),
      ]),
      _c(
        "ui-tooltip",
        { attrs: { tooltip: _vm.tooltip } },
        [
          _c("ui-icon", {
            staticClass: "ml-1",
            attrs: {
              color: _vm.GRAYSCALE.inkLight,
              size: _vm.ICONS_SIZES.small,
              icon: _vm.ICONS.infoFull,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
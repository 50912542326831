<script>
import { getDateDiff } from '../utils/dateDiff';
import { LABELS } from '../const/labels.const';

export default {
  name: 'ReachabilityComponent',
  props: {
    lastReceivedDate: {
      type: Number,
      required: true,
    },
  },
  computed: {
    reachabilityInfo() {
      if (this.lastReceivedDate === -1) {
        return LABELS.neverReceived;
      }

      return getDateDiff(this.lastReceivedDate);
    },
  },
};
</script>
<template>
  <span data-test-id="reachability-cell">
    {{ reachabilityInfo }}
  </span>
</template>


<script>
import get from 'lodash/get';
import size from 'lodash/size';
import reduce from 'lodash/reduce';
import camelCase from 'lodash/camelCase';
import map from 'lodash/map';
import moment from 'moment';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  DELAY,
  exportFile,
  navigationErrorHandler,
  TIME_ZONE,
} from '@emobg/web-utils';
import { MuiAlgoliaList, MuiModal } from '@emobg/motion-ui/v1';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { NOTIFICATION_TYPES } from '@/constants/notifications';

import {
  contentCells,
  disjunctiveFacets,
  disjunctiveFacetsRefinements,
  vehicleListFacets,
} from './config/vehiclesContentCells';
import carRental from '../store/CarrentalModuleMap';
import { carRentalErrors } from '../const/errors';

import { CARRENTAL_FLAGS } from '../const/features';

export default {
  name: 'VehiclesView',
  components: {
    MuiModal,
    MuiAlgoliaList,
  },
  beforeRouteLeave(to, _from, next) {
    if (to && this.isExportStarted && !this.isForcedNavigation) {
      this.urlName = to;
      this.isModalOpen = true;
    } else {
      next();
    }
  },
  data() {
    return {
      vehicleListFacets: [],
      isModalOpen: false,
      isForcedNavigation: false,
      isExportLoading: false,
      urlName: '',
      modalConfig: {
        title: 'Exporting vehicle list',
        header: {
          class: 'pl-3',
          isClosable: true,
        },
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => get(state, 'operators.active.uuid'),
      activeOperatorTimeZone: state => get(state, 'operators.active.timezone') || TIME_ZONE.default,
      features: state => get(state, 'features.data'),
    }),
    ...mapState(carRental.vehicle.summary, {
      isExportStarted: state => state.list.STATUS.LOADING,
      hasExportFailed: state => state.list.STATUS.ERROR,
      stations: state => get(state, 'stations.data') || [],
      csvData: state => state.list.data,
    }),
    areCamelCasedFiltersEnabled() {
      return this.features[CARRENTAL_FLAGS.camelCasedFiltersEnabled];
    },
  },
  watch: {
    hasExportFailed(newValue) {
      if (newValue) {
        this.throwError({ message: carRentalErrors.vehiclesExportFailed, delay: DELAY.extraLong });
      }
    },
  },
  async mounted() {
    await this.getStations();
    this.vehicleListFacets = vehicleListFacets(this.stations);
  },
  created() {
    this.contentCells = contentCells;
    this.disjunctiveFacets = disjunctiveFacets;
    this.disjunctiveFacetsRefinements = disjunctiveFacetsRefinements;
    this.CARRENTAL_FLAGS = CARRENTAL_FLAGS;
  },
  destroyed() {
    this.resetSummaryData();
  },
  methods: {
    ...mapActions(carRental.vehicle.summary, [
      'getVehicleExport',
      'getVehiclesExport',
      'getStations',
    ]),
    ...mapMutations(carRental.vehicle.summary, [
      'resetSummaryData',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.errors, [
      'throwError',
    ]),
    async exportCsv(activeFilters = {}) {
      this.isExportLoading = true;

      this.$notify({
        message: 'The vehicle list export has started. This process might take a while.',
        delay: DELAY.extraLong,
        type: NOTIFICATION_TYPES.info,
      });

      const algoliaFilters = reduce(
        activeFilters,
        (_filters, filterValues, filterName) => {
          const parameterName = this.areCamelCasedFiltersEnabled ? camelCase(filterName)
            : filterName;
          _filters[parameterName] = map(filterValues, 'value').join(','); // eslint-disable-line no-param-reassign
          return _filters;
        },
        {},
      );
      const operatorFilter = this.areCamelCasedFiltersEnabled
        ? { operatorId: this.activeOperatorUuid } : { operator_id: this.activeOperatorUuid };
      const filters = {
        ...operatorFilter,
        timeZone: this.activeOperatorTimeZone,
        ...algoliaFilters,
      };
      await this.getVehiclesExport({ filters });
      if (!this.hasExportFailed && !size(this.csvData)) {
        this.throwError({ message: carRentalErrors.empty, delay: DELAY.extraLong });
        this.isExportLoading = false;
        return;
      }
      if (!this.hasExportFailed) {
        const filename = moment().format('[Vehicles_list_]YYYY-MM-DD_HH[h]mm[m]SS[s.csv]');
        this.exportFile(this.csvData, filename);
      }
      this.isExportLoading = false;
    },
    navigate() {
      this.isModalOpen = false;
      this.isForcedNavigation = true;
      this.$router.push(this.urlName).catch(navigationErrorHandler);
    },
    exportFile,
  },
};
</script>

<template>
  <div
    class="VehiclesView px-2 mt-3 px-lg-5"
    data-test-id="vehicles-view"
  >
    <h1 class="pb-4 d-flex justify-content-between justify-center">
      Vehicles
    </h1>
    <MuiAlgoliaList
      data-test-id="list"
      :facets="vehicleListFacets"
      :filters="`operator_id: ${activeOperatorUuid}`"
      :query-parameters="{ disjunctiveFacets, disjunctiveFacetsRefinements }"
      :table-config="contentCells"
      :is-export-button-loading="isExportLoading"
      :is-export-enabled="features[CARRENTAL_FLAGS.exportVehicleList]"
      export-button-label="Export results"
      index="connexus_algolia_projector_vehicle"
      @on:export="exportCsv"
    />
    <MuiModal
      v-model="isModalOpen"
      data-test-id="leave_page_feedback-modal"
      v-bind="modalConfig"
      @close-modal="isModalOpen = false"
    >
      <div slot="body">
        <div
          data-test-id="warning"
          class="py-3"
        >
          If you leave the page the export may be interrupted
        </div>
      </div>
      <div
        slot="footer"
        class="d-flex justify-content-end p-3"
      >
        <ui-button
          data-test-id="cancel-button"
          :color="GRAYSCALE.white"
          class="emobg-color-white-contrast"
          @clickbutton="isModalOpen = false"
        >
          Cancel
        </ui-button>
        <ui-button
          data-test-id="navigate-button"
          class="ml-4"
          @clickbutton="navigate()"
        >
          OK
        </ui-button>
      </div>
    </MuiModal>
  </div>
</template>

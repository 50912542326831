export const CARRENTAL_FLAGS = {
  exportVehicleList: 'msbo-digital-station-export-vehicle-list',
  exportFleetList: 'msbo-digital-station-export-fleet-list',
  manageZones: 'msbo-digital-station-manage-zones',
  vehicleTelemetry: 'msbo-digital-station-vehicle-telemetry-tab',
  vehicleTripsTab: 'msbo-digital-station-vehicle-trips-tab',
  reopenAlert: 'msbo-digital-station-reopen-alert',
  operatorLocations: 'msbo-digital-station-operator-locations',
  importAgents: 'msbo-digital-station-import-agents',
  promoteToAgent: 'msbo-digital-station-promote-to-agent',
  editAgent: 'msbo-digital-station-edit-agent',
  removeAgent: 'msbo-digital-station-remove-agent',
  validateReadings: 'msbo-digital-station-readings-validation',
  collisionReport: 'msbo-digital-station-collision-report',
  collisionReportExport: 'msbo-digital-station-collision-report-export',
  stationsSelection: 'msbo-digital-station-alert-by-email-stations-dropdown',
  hideZones: 'msbo-digital-station-hide-turnaround-zones',
  devicesBulkActivation: 'msbo-digital-station-devices-bulk-activation',
  camelCasedFiltersEnabled: 'msbo-digital-station-camelcase-export-vehicle-list',
};

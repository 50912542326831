var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "BulkActivation row",
      attrs: { "data-test-id": "bulk_activation" },
    },
    [
      _c("div", { staticClass: "col-6" }, [
        _c("p", { staticClass: "pb-4 emobg-body-2" }, [
          _vm._v(
            " Upload a [CSV, XLS, XLSX] file with the following criteria: "
          ),
        ]),
        _c(
          "div",
          { staticClass: "pb-6" },
          _vm._l(_vm.INSTRUCTIONS, function (instruction, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "row emobg-caption-1 pb-2",
                attrs: { "data-test-id": "instructions" },
              },
              [
                _c("div", { staticClass: "col-4 emobg-caption-2" }, [
                  _vm._v(" " + _vm._s(_vm.sentenceCase(instruction.key)) + " "),
                ]),
                _c("div", { staticClass: "col-8 emobg-color-ink-lighter" }, [
                  _vm._v(" " + _vm._s(instruction.label) + " "),
                ]),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          {
            staticClass:
              "d-flex align-items-center justify-content-between pt-6 pb-2",
          },
          [
            _c("p", { staticClass: "emobg-body-2" }, [_vm._v(" Example: ")]),
            _c(
              "a",
              {
                attrs: {
                  download: "",
                  href: "/carrental/OEM_request_example.xls",
                },
              },
              [
                _c(
                  "ui-button",
                  {
                    attrs: {
                      face: _vm.FACES.outline,
                      "data-test-id": "download_template-button",
                    },
                  },
                  [_vm._v(" Download template ")]
                ),
              ],
              1
            ),
          ]
        ),
        _c("img", {
          staticClass: "w-100",
          attrs: {
            src: require(_vm.mode === _vm.MODES.activate
              ? "../images/excel_activation_example.png"
              : "../images/excel_deactivation_example.png"),
            alt: "excel example",
          },
        }),
      ]),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          {
            staticClass:
              "BulkActivation__dropArea h-100 p-4 d-flex align-items-center justify-content-center emobg-border-1 emobg-border-color-ground-light emobg-background-color-ground-lightest",
            class: {
              "emobg-border-color-primary emobg-background-color-primary-lightest":
                _vm.isDragOver || _vm.isProcessing,
              "emobg-border-color-success": _vm.hasWrongDevices,
              "emobg-border-color-danger": _vm.hasError,
            },
          },
          [
            _c("DragFile", {
              ref: "dragInput",
              attrs: { "data-test-id": "drag_file" },
              on: {
                file: _vm.processFile,
                dragOver: (value) => (_vm.isDragOver = value),
                wrongExtension: function ($event) {
                  _vm.errorType = _vm.ERROR_TYPES.fileExtension
                },
              },
            }),
            !_vm.isDragOver && _vm.hasError
              ? [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column align-items-center justify-content-center emobg-caption-1 emobg-color-danger",
                    },
                    [
                      _c("ui-icon", {
                        staticClass: "mb-1",
                        attrs: {
                          color: _vm.GRAYSCALE.danger,
                          size: _vm.ICONS_SIZES.huge,
                          icon: _vm.ICONS.alertFull,
                        },
                      }),
                      _c("p", { staticClass: "text-center mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
                      ]),
                      _c(
                        "ui-button",
                        {
                          attrs: { "data-test-id": "try_again-button" },
                          on: { clickbutton: _vm.openFileSelector },
                        },
                        [_vm._v(" Select file ")]
                      ),
                    ],
                    1
                  ),
                ]
              : !_vm.isDragOver && _vm.hasWrongDevices
              ? [
                  _c("div", { staticClass: "d-flex flex-column h-100" }, [
                    _c("h3", { staticClass: "mb-4" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            `Your file ${_vm.fileName} was successfully uploaded.`
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center mb-3" },
                      [
                        _c("ui-icon", {
                          staticClass: "mr-3",
                          attrs: {
                            color: _vm.COLORS.success,
                            icon: _vm.ICONS.bold.check,
                          },
                        }),
                        _c(
                          "p",
                          { staticClass: "emobg-color-ink emobg-body-1" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  `${
                                    _vm.fileData.length -
                                    _vm.wrongDevices.length
                                  } were uploaded correctly`
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center mb-4" },
                      [
                        _c("ui-icon", {
                          staticClass: "mr-3",
                          attrs: {
                            color: _vm.COLORS.danger,
                            icon: _vm.ICONS.alertFull,
                          },
                        }),
                        _c(
                          "p",
                          { staticClass: "emobg-color-ink emobg-body-1" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  `${_vm.wrongDevices.length} have errors and need to be fixed`
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("h4", { staticClass: "mb-3" }, [
                      _vm._v(" Devices not imported "),
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-fill" },
                      [
                        _c("MuiTable", {
                          staticClass:
                            "BulkActivation__wrongsDevicesTable mx-0",
                          attrs: {
                            "content-cells": _vm.contentCells,
                            "data-set": _vm.wrongRowsData,
                            "no-data-label": _vm.FALLBACK_MESSAGE.dash,
                            "data-test-id": "errors-table",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "emobg-body-1 pb-3 my-3 emobg-color-ink emobg-border-bottom-1 emobg-border-color-ground-light",
                      },
                      [
                        _vm._v(
                          " If you want to upload the vehicles that failed: download the csv with errors, fix them and upload the file again. "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c(
                          "ui-button",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              face: _vm.FACES.outline,
                              "data-test-id": "download_errors_csv-button",
                            },
                            on: { clickbutton: _vm.downloadErrorsCsv },
                          },
                          [_vm._v(" Download CSV with errors ")]
                        ),
                        _c(
                          "ui-button",
                          {
                            attrs: {
                              face: _vm.FACES.fill,
                              "data-test-id": "upload_again-button",
                            },
                            on: { clickbutton: _vm.openFileSelector },
                          },
                          [_vm._v(" Upload again ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              : [
                  _vm.isProcessing
                    ? _c("ui-loader", {
                        attrs: {
                          color: _vm.COLORS.primary,
                          "data-test-id": "activating-loader",
                          label: "Processing file",
                        },
                      })
                    : _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column align-items-center justify-content-center",
                        },
                        [
                          _c("ui-icon", {
                            staticClass: "mb-2",
                            attrs: {
                              color: _vm.isDragOver
                                ? _vm.COLORS.primary
                                : _vm.GRAYSCALE.inkLight,
                              size: _vm.ICONS_SIZES.huge,
                              icon: _vm.ICONS.upload,
                            },
                          }),
                          _vm.isDragOver
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "emobg-color-primary emobg-caption-1",
                                },
                                [
                                  _vm._v(
                                    " Upload your file by dropping them here "
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "emobg-body-1 emobg-color-ink-light",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-center",
                                    },
                                    [
                                      _vm._v(" Drop your files or "),
                                      _c(
                                        "ui-button",
                                        {
                                          staticClass:
                                            "d-block ml-1 BulkActivation__textButton",
                                          attrs: {
                                            face: _vm.FACES.text,
                                            size: _vm.SIZES.xSmall,
                                            compact: "",
                                            "data-test-id": "browse-button",
                                          },
                                          on: {
                                            clickbutton: _vm.openFileSelector,
                                          },
                                        },
                                        [_vm._v(" browse ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("p", { staticClass: "mt-2" }, [
                                    _vm._v(" (CSV, XLS, XLSX files) "),
                                  ]),
                                ]
                              ),
                        ],
                        1
                      ),
                ],
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  TIME_ZONE,
  formatUtc,
  sentenceCase,
} from '@emobg/web-utils';
import toLower from 'lodash/toLower';
import { ALGOLIA_REFINEMENT_OPTIONS } from '@emobg/motion-ui/v1';

import { DEVICE_DATA_STATUSES, DEVICE_INSTALLATION_STATUSES } from '../const/deviceStatus.const';
import DeviceStatusBadge from '../components/DeviceStatusBadge';
import TooltipHeader from '../../components/TooltipHeader.vue';

import { HEADER_TOOLTIPS } from '../const/tooltips.const';

const ALL_STATUSES = { ...DEVICE_INSTALLATION_STATUSES, ...DEVICE_DATA_STATUSES };

export const contentCells = ({ operatorTimezone = TIME_ZONE.default }) => [
  {
    property: 'serial_number',
    title: 'Serial number',
    displayPriority: 1,
    width: 168,
    headerComponent: TooltipHeader,
    headerProps: () => ({ tooltip: HEADER_TOOLTIPS.serial_number }),
  },
  {
    property: 'type',
    title: 'Type',
    displayPriority: 1,
    width: 80,
  },
  {
    property: 'country',
    title: 'Country',
    displayPriority: 1,
    width: 90,
  },
  {
    property: 'vin',
    title: 'VIN number',
    displayPriority: 1,
    width: 170,
    transformValue: value => (value || 'Not installed yet'),
  },
  {
    property: 'license_plate',
    title: 'Reg. number',
    displayPriority: 1,
    width: 70,
  },
  {
    property: 'status_updated_date',
    title: 'Device update date',
    displayPriority: 1,
    width: 170,
    classes: 'ellipsis',
    transformValue: value => (value.status && value.updated_at)
      ? `${ALL_STATUSES[toLower(value.status)]}: ${formatUtc(value.updated_at, DATE_FORMAT.defaultExtended, operatorTimezone)}`
      : FALLBACK_MESSAGE.dash,
    headerComponent: TooltipHeader,
    headerProps: () => ({ tooltip: HEADER_TOOLTIPS.status_updated_date }),
  },
  {
    property: 'device_data_status',
    title: 'Data status',
    displayPriority: 1,
    width: 100,
    component: DeviceStatusBadge,
    props: result => ({ status: DEVICE_DATA_STATUSES[toLower(result.device_data_status)] }),
    headerComponent: TooltipHeader,
    headerProps: () => ({ tooltip: HEADER_TOOLTIPS.device_data_status }),
  },
  {
    property: 'device_installation_status',
    title: 'Installation status',
    displayPriority: 1,
    width: 90,
    component: DeviceStatusBadge,
    props: result => ({ status: DEVICE_INSTALLATION_STATUSES[toLower(result.device_installation_status)] }),
    headerComponent: TooltipHeader,
    headerProps: () => ({ tooltip: HEADER_TOOLTIPS.device_installation_status }),
  },
];

export const deviceListFacets = [
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Device type',
      attributeName: 'type',
      sortFacetsBy: ['name:asc'],
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Device installation status',
      attributeName: 'device_installation_status',
      sortFacetsBy: ['count:desc'],
      transformValue: sentenceCase,
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Device data status',
      attributeName: 'device_data_status',
      sortFacetsBy: ['count:desc'],
      transformValue: sentenceCase,
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Device country',
      attributeName: 'country',
      sortFacetsBy: ['name:asc'],
    },
  },
];

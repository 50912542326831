var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { attrs: { "data-test-id": "reachability-cell" } }, [
    _vm._v(" " + _vm._s(_vm.reachabilityInfo) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
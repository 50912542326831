var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "VehiclesView px-2 mt-3 px-lg-5",
      attrs: { "data-test-id": "vehicles-view" },
    },
    [
      _c(
        "h1",
        { staticClass: "pb-4 d-flex justify-content-between justify-center" },
        [_vm._v(" Vehicles ")]
      ),
      _c("MuiAlgoliaList", {
        attrs: {
          "data-test-id": "list",
          facets: _vm.vehicleListFacets,
          filters: `operator_id: ${_vm.activeOperatorUuid}`,
          "query-parameters": {
            disjunctiveFacets: _vm.disjunctiveFacets,
            disjunctiveFacetsRefinements: _vm.disjunctiveFacetsRefinements,
          },
          "table-config": _vm.contentCells,
          "is-export-button-loading": _vm.isExportLoading,
          "is-export-enabled":
            _vm.features[_vm.CARRENTAL_FLAGS.exportVehicleList],
          "export-button-label": "Export results",
          index: "connexus_algolia_projector_vehicle",
        },
        on: { "on:export": _vm.exportCsv },
      }),
      _c(
        "MuiModal",
        _vm._b(
          {
            attrs: { "data-test-id": "leave_page_feedback-modal" },
            on: {
              "close-modal": function ($event) {
                _vm.isModalOpen = false
              },
            },
            model: {
              value: _vm.isModalOpen,
              callback: function ($$v) {
                _vm.isModalOpen = $$v
              },
              expression: "isModalOpen",
            },
          },
          "MuiModal",
          _vm.modalConfig,
          false
        ),
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c(
              "div",
              { staticClass: "py-3", attrs: { "data-test-id": "warning" } },
              [_vm._v(" If you leave the page the export may be interrupted ")]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-end p-3",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "ui-button",
                {
                  staticClass: "emobg-color-white-contrast",
                  attrs: {
                    "data-test-id": "cancel-button",
                    color: _vm.GRAYSCALE.white,
                  },
                  on: {
                    clickbutton: function ($event) {
                      _vm.isModalOpen = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "ui-button",
                {
                  staticClass: "ml-4",
                  attrs: { "data-test-id": "navigate-button" },
                  on: {
                    clickbutton: function ($event) {
                      return _vm.navigate()
                    },
                  },
                },
                [_vm._v(" OK ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
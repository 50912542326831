export const contentCells = [
  {
    property: 'row',
    title: 'Row',
    displayPriority: 1,
    width: 55,
    maxWidth: 55,
  },
  {
    property: 'vin',
    title: 'Vin',
    displayPriority: 1,
    width: 150,
  },
  {
    property: 'error',
    title: 'Error',
    displayPriority: 1,
    width: 200,
  },
];

<script>
import toLower from 'lodash/toLower';
import { sentenceCase } from '@emobg/web-utils';
import { STATUS_CLASSES } from '../const/deviceStatus.const';

export default {
  name: 'DeviceStatusBadge',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  computed: {
    badgeColor() {
      return STATUS_CLASSES[toLower(this.status)] || STATUS_CLASSES.fallback;
    },
  },
  methods: {
    sentenceCase,
  },
};
</script>

<template>
  <div class="emobg-font-weight-normal">
    <ui-badge
      v-if="status"
      :color="badgeColor"
    >
      {{ sentenceCase(status) }}
    </ui-badge>
    <span v-else>{{ FALLBACK_MESSAGE.dash }}</span>
  </div>
</template>

export const ERROR_TYPES = {
  fileFormat: 'fileFormat',
  fileExtension: 'fileExtension',
  fileWithEmptyVin: 'fileWithEmptyVin',
  upload: 'upload',
};

export const ERROR_MESSAGES = {
  fileFormat: 'Ups! Column names don’t mach the actual criteria of our system. Please upload the file with the same format as we show in the template.',
  fileExtension: 'Ups! Your file needs to be a csv, xls or xlsx.',
  fileWithEmptyVin: 'Ups! The file uploaded has empty VIN values',
  upload: 'Ups! Upload error!',
};

export const DEVICE_DATA_STATUSES = {
  created: 'Created',
  'in progress': 'In progress',
  activated: 'Activated',
  deactivation: 'Deactivation',
  deactivated: 'Deactivated',
  terminated: 'Terminated',
  rejected: 'Rejected',
  error: 'Error',
};

export const DEVICE_INSTALLATION_STATUSES = {
  pending: 'Pending',
  uninstalled: 'Uninstalled',
  installed: 'Installed',
};

export const STATUS_CLASSES = {
  uninstalled: 'danger',
  deactivated: 'danger',
  activated: 'success',
  installed: 'success',
  rejected: 'warning',
  error: 'warning',
  created: 'ground',
  pending: 'ground',
  'in progress': 'ground',
  terminated: 'moderate',
  fallback: 'ground',
};

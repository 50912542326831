<script>
import get from 'lodash/get';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import slice from 'lodash/slice';
import keys from 'lodash/keys';
import first from 'lodash/first';
import isNil from 'lodash/isNil';
import includes from 'lodash/includes';
import trim from 'lodash/trim';
import xlsx from 'xlsx';
import moment from 'moment';
import { mapActions, mapMutations, mapState } from 'vuex';

import { MuiTable } from '@emobg/vue-base';
import { exportFile, sentenceCase, STORE_REQUEST_STATUS } from '@emobg/web-utils';

import { DragFile } from '@/components';
import carRental from '../../store/CarrentalModuleMap';

import DOMAINS_MODEL from '../../../DOMAINS_MODEL';

import { scopes } from '../store/DevicesModule';
import { INSTRUCTIONS } from '../const/bulkFile.const';
import { MODES } from '../const/bulkProcess.const';
import { ERROR_MESSAGES, ERROR_TYPES } from '../const/error.const';
import { contentCells } from '../config/wrongDevicesTableConfig';
import {
  hasIncorrectHeaders,
  readHeaders,
} from '../utils/bulkActivationFile';

export default {
  name: 'BulkActivation',
  components: {
    DragFile,
    MuiTable,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isDragOver: false,
      errorType: null,
      fileName: null,
      fileHeaders: [],
      fileData: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => get(state, 'operators.active.uuid'),
      userUuid: state => get(state, 'user.data.uuid'),
    }),
    ...mapState(carRental.devices, {
      isProcessing: state => state.activate.STATUS.LOADING || state.deactivate.STATUS.LOADING,
      bulkProcessState: state => state,
      bulkProcessError: state => state.activate.error || state.deactivate.error,
    }),
    hasError() {
      return !!this.errorType;
    },
    errorMessage() {
      return this.hasError ? ERROR_MESSAGES[this.errorType] : null;
    },
    wrongDevices() {
      return this.mode
        ? this.bulkProcessState[this.mode].STATUS.LOADED && get(this.bulkProcessState[this.mode].data, 'errors', [])
        : [];
    },
    hasWrongDevices() {
      return !!this.wrongDevices.length;
    },
    wrongRowsData() {
      return map(this.wrongDevices, row => {
        // xls or csv file has headers and starts in index 1. fileData does not have headers and starts in index 0. That's why we add 2 to the index.
        const rowNumber = findIndex(this.fileData, ['vin', row.vin]) + 2;
        return {
          row: `${rowNumber}`,
          vin: row.vin,
          error: row.error,
        };
      });
    },
  },
  watch: {
    bulkProcessError(newValue) {
      if (newValue) {
        this.errorType = ERROR_TYPES.upload;
      }
    },
  },
  created() {
    this.contentCells = contentCells;
    this.INSTRUCTIONS = INSTRUCTIONS;
    this.ERROR_TYPES = ERROR_TYPES;
    this.MODES = MODES;
  },
  methods: {
    sentenceCase,
    ...mapActions(carRental.devices, [
      'putActivateOemDevices',
    ]),
    ...mapMutations(carRental.devices, [
      'setStatus',
    ]),
    openFileSelector() {
      this.$refs.dragInput.openFileSelector();
    },
    processFile(file) {
      this.errorType = null;
      this.setStatus({ value: STORE_REQUEST_STATUS.loading, scope: scopes.activate });
      this.handleFile(file);
    },
    handleFile(file) {
      this.fileName = file.name;
      const reader = new FileReader();
      reader.onload = async event => {
        const data = new Uint8Array(event.target.result);
        const workbook = xlsx.read(data, { type: 'array' });
        const wbSheetNames = workbook.SheetNames;
        const sheet = workbook.Sheets[wbSheetNames[0]];
        const sheetData = xlsx.utils.sheet_to_json(sheet, { header: 1, blankrows: false });
        this.fileHeaders = readHeaders(sheetData);
        if (hasIncorrectHeaders(this.fileHeaders)) {
          this.errorType = ERROR_TYPES.fileFormat;
          this.setStatus({ value: STORE_REQUEST_STATUS.loaded, scope: scopes.activate });
          return;
        }
        this.fileData = this.getFileData(sheetData);
        const dataWithEmptyVins = this.fileData.filter(fileRow => !fileRow.vin);
        if (!isEmpty(dataWithEmptyVins)) {
          this.errorType = ERROR_TYPES.fileWithEmptyVin;
          this.setStatus({ value: STORE_REQUEST_STATUS.loaded, scope: scopes.activate });
          return;
        }
        await this.putActivateOemDevices({ operatorUuid: this.activeOperatorUuid, data: { vehicles: this.fileData, userId: this.userUuid } });
        // This means that all rows were activated without any error
        if (!this.hasError && !this.hasWrongDevices) {
          this.$emit('success', this.fileData.length);
        }
      };
      reader.readAsArrayBuffer(file);
    },
    getFileData(file) {
      // Omit first row of the file because it's headers.
      return map(slice(file, 1), row => reduce(row, (acc, value, key) => {
        const headerName = this.fileHeaders[key];
        // This is to avoid adding data random in the xls file.
        if (headerName) {
          acc[headerName] = trim(value);
          return acc;
        }
        return {};
      }, {})).filter(data => !isEmpty(data));
    },
    downloadErrorsCsv() {
      const csvContent = this.getCsvContent();
      const filename = moment().format('[Error_devices_list_]YYYY-MM-DD_HH[h]mm[m]SS[s.csv]');
      exportFile(csvContent, filename);
    },
    getCsvContent() {
      const exportKeys = keys(first(this.wrongRowsData));
      const mappedRows = map(this.wrongRowsData, row => exportKeys
        .map(key => {
          if (isNil(row[key])) {
            return this.FALLBACK_MESSAGE.noData;
          }
          return includes(row[key], ',') ? `"${row[key]}"` : row[key];
        })
        .join(','))
        .join('\n');
      return `${exportKeys.join(',')}\n${mappedRows}`;
    },
  },
};
</script>

<template>
  <div
    class="BulkActivation row"
    data-test-id="bulk_activation"
  >
    <div class="col-6">
      <p class="pb-4 emobg-body-2">
        Upload a [CSV, XLS, XLSX] file with the following criteria:
      </p>
      <div class="pb-6">
        <div
          v-for="(instruction, index) in INSTRUCTIONS"
          :key="index"
          data-test-id="instructions"
          class="row emobg-caption-1 pb-2"
        >
          <div class="col-4 emobg-caption-2">
            {{ sentenceCase(instruction.key) }}
          </div>
          <div class="col-8 emobg-color-ink-lighter">
            {{ instruction.label }}
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between pt-6 pb-2">
        <p class="emobg-body-2">
          Example:
        </p>
        <a
          download
          href="/carrental/OEM_request_example.xls"
        >
          <ui-button
            :face="FACES.outline"
            data-test-id="download_template-button"
          >
            Download template
          </ui-button>
        </a>
      </div>
      <img
        class="w-100"
        :src="require(mode === MODES.activate ? '../images/excel_activation_example.png' : '../images/excel_deactivation_example.png')"
        alt="excel example"
      >
    </div>
    <div class="col-6">
      <div
        class="
          BulkActivation__dropArea h-100 p-4 d-flex align-items-center justify-content-center
          emobg-border-1 emobg-border-color-ground-light emobg-background-color-ground-lightest
        "
        :class="{
          'emobg-border-color-primary emobg-background-color-primary-lightest': isDragOver || isProcessing,
          'emobg-border-color-success': hasWrongDevices,
          'emobg-border-color-danger': hasError,
        }"
      >
        <DragFile
          ref="dragInput"
          data-test-id="drag_file"
          @file="processFile"
          @dragOver="value => isDragOver = value"
          @wrongExtension="errorType = ERROR_TYPES.fileExtension"
        />
        <template v-if="!isDragOver && hasError">
          <div class="d-flex flex-column align-items-center justify-content-center emobg-caption-1 emobg-color-danger">
            <ui-icon
              :color="GRAYSCALE.danger"
              :size="ICONS_SIZES.huge"
              :icon="ICONS.alertFull"
              class="mb-1"
            />
            <p class="text-center mb-2">
              {{ errorMessage }}
            </p>
            <ui-button
              data-test-id="try_again-button"
              @clickbutton="openFileSelector"
            >
              Select file
            </ui-button>
          </div>
        </template>
        <template v-else-if="!isDragOver && hasWrongDevices">
          <div class="d-flex flex-column h-100">
            <h3 class="mb-4">
              {{ `Your file ${fileName} was successfully uploaded.` }}
            </h3>
            <div class="d-flex align-items-center mb-3">
              <ui-icon
                :color="COLORS.success"
                :icon="ICONS.bold.check"
                class="mr-3"
              />
              <p class="emobg-color-ink emobg-body-1">
                {{ `${fileData.length - wrongDevices.length} were uploaded correctly` }}
              </p>
            </div>
            <div class="d-flex align-items-center mb-4">
              <ui-icon
                :color="COLORS.danger"
                :icon="ICONS.alertFull"
                class="mr-3"
              />
              <p class="emobg-color-ink emobg-body-1">
                {{ `${wrongDevices.length} have errors and need to be fixed` }}
              </p>
            </div>
            <h4 class="mb-3">
              Devices not imported
            </h4>
            <div class="d-flex flex-fill">
              <MuiTable
                :content-cells="contentCells"
                :data-set="wrongRowsData"
                :no-data-label="FALLBACK_MESSAGE.dash"
                class="BulkActivation__wrongsDevicesTable mx-0"
                data-test-id="errors-table"
              />
            </div>
            <div class="emobg-body-1 pb-3 my-3 emobg-color-ink emobg-border-bottom-1 emobg-border-color-ground-light">
              If you want to upload the vehicles that failed: download the csv with errors, fix them and upload the file again.
            </div>
            <div class="d-flex align-items-center">
              <ui-button
                :face="FACES.outline"
                class="mr-2"
                data-test-id="download_errors_csv-button"
                @clickbutton="downloadErrorsCsv"
              >
                Download CSV with errors
              </ui-button>
              <ui-button
                :face="FACES.fill"
                data-test-id="upload_again-button"
                @clickbutton="openFileSelector"
              >
                Upload again
              </ui-button>
            </div>
          </div>
        </template>
        <template v-else>
          <ui-loader
            v-if="isProcessing"
            :color="COLORS.primary"
            data-test-id="activating-loader"
            label="Processing file"
          />
          <div
            v-else
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <ui-icon
              :color="isDragOver ? COLORS.primary : GRAYSCALE.inkLight"
              :size="ICONS_SIZES.huge"
              :icon="ICONS.upload"
              class="mb-2"
            />
            <p
              v-if="isDragOver"
              class="emobg-color-primary emobg-caption-1"
            >
              Upload your file by dropping them here
            </p>
            <div
              v-else
              class="emobg-body-1 emobg-color-ink-light"
            >
              <div class="d-flex align-items-center justify-content-center">
                Drop your files or
                <ui-button
                  :face="FACES.text"
                  :size="SIZES.xSmall"
                  compact
                  class="d-block ml-1 BulkActivation__textButton"
                  data-test-id="browse-button"
                  @clickbutton="openFileSelector"
                >
                  browse
                </ui-button>
              </div>
              <p class="mt-2">
                (CSV, XLS, XLSX files)
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

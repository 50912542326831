var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    _vm._b(
      {
        staticClass: "BulkActivationModal",
        attrs: {
          size: _vm.SIZES.large,
          "data-test-id": "oem_bulk_activation-modal",
        },
        on: { "modal-closed": _vm.onModalClosed },
        model: {
          value: _vm.isOpen,
          callback: function ($$v) {
            _vm.isOpen = $$v
          },
          expression: "isOpen",
        },
      },
      "MuiModal",
      _vm.modalConfig,
      false
    ),
    [
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _vm.step === _vm.STEPS.bulkProcess
            ? _c("BulkActivation", {
                attrs: { mode: _vm.mode, "data-test-id": "bulk_activation" },
                on: { success: _vm.onBulkSuccess },
              })
            : _c(
                "div",
                {
                  staticClass:
                    "BulkActivationModal__successBox row no-gutters px-4 emobg-border-1 emobg-border-color-success emobg-background-color-ground-lightest",
                  attrs: { "data-test-id": "success_box" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 d-flex flex-column align-items-center justify-content-center pr-2",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-start w-100 mb-5" },
                        [
                          _c("ui-icon", {
                            staticClass: "mr-4",
                            attrs: {
                              color: _vm.GRAYSCALE.success,
                              size: _vm.ICONS_SIZES.xLarge,
                              icon: _vm.ICONS.bold.check,
                            },
                          }),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "emobg-body-2 emobg-color-success mb-2",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      `Your ${
                                        _vm.mode === _vm.MODES.activate
                                          ? "activation"
                                          : "deactivation"
                                      } OEM file was succesfully uploaded`
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "emobg-caption-1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.rowsProcessed} of ${_vm.rowsProcessed}`
                                  ) +
                                  " "
                              ),
                              _c(
                                "span",
                                { staticClass: "emobg-color-ink-light" },
                                [_vm._v("devices were uploaded")]
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-start w-100 mt-5" },
                        [
                          _c("ui-icon", {
                            staticClass: "mr-4",
                            attrs: {
                              color: _vm.GRAYSCALE.inkLighter,
                              size: _vm.ICONS_SIZES.xLarge,
                              icon: _vm.ICONS.bold.check,
                            },
                          }),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("p", { staticClass: "emobg-body-2 mb-3" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `Device ${
                                      _vm.mode === _vm.MODES.activate
                                        ? "activation"
                                        : "deactivation"
                                    }`
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("p", { staticClass: "emobg-caption-1" }, [
                              _vm._v(
                                " The activation process may take up to 72h. "
                              ),
                            ]),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "emobg-caption-1 emobg-color-ink-light mb-4",
                              },
                              [
                                _vm._v(
                                  " Once you confirm, the activation process with the OEM will begin. When the activation process finished successfully the data status will change to ACTIVATED. However, if the vehicle is not eligible the activation process will be rejected and the data status will change to REJECTED. "
                                ),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "emobg-caption-1 emobg-color-ink-light",
                                class: {
                                  "mb-4": _vm.mode === _vm.MODES.deactivate,
                                },
                              },
                              [
                                _vm._v(
                                  " If you get an ERROR status then a new request should be done. If by retrying the error persists, then it will need to be reported. "
                                ),
                              ]
                            ),
                            _vm.mode === _vm.MODES.deactivate
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "emobg-caption-1 emobg-color-ink-light",
                                  },
                                  [
                                    _vm._v(
                                      " Also, remember that you can activate OEM devices again. "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center col-6" },
                    [
                      _c("img", {
                        staticClass: "w-100",
                        attrs: {
                          src: require("../images/devices_uploaded.png"),
                          alt: "devices uploaded",
                        },
                      }),
                    ]
                  ),
                ]
              ),
        ],
        1
      ),
      _vm.step === _vm.STEPS.processSuccess
        ? _c(
            "div",
            {
              staticClass: "p-3 d-flex justify-content-end",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "ui-button",
                {
                  attrs: { "data-test-id": "understood-button" },
                  on: {
                    clickbutton: function ($event) {
                      return _vm.$emit("bulkSuccess")
                    },
                  },
                },
                [_vm._v(" Understood ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<script>
import get from 'lodash/get';
import find from 'lodash/find';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  name: 'StationComponent',
  props: {
    stationCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carRental.vehicle.summary, {
      stations: state => get(state, 'stations.data') || [],
    }),
    tooltipText() {
      const station = find(this.stations, { greenwayId: this.stationCode });
      return station ? `${station.greenwayId} - ${station.greenwayName}` : this.stationCode;
    },
  },
};
</script>
<template>
  <span data-test-id="station">
    <ui-tooltip :tooltip="tooltipText">
      {{ stationCode }}
    </ui-tooltip>
  </span>
</template>

<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import { MuiAlgoliaList, MuiModal } from '@emobg/motion-ui/v1';
import { DELAY, rehydrate, TIME_ZONE } from '@emobg/web-utils';

import { getFacetValues, getStoreTotalResults, refreshAlgoliaStore } from '@/utils';

import DOMAINS_MODEL from '../../DOMAINS_MODEL';
import { CARRENTAL_FLAGS } from '../const/features';
import TotalsInfoBoxes from './components/TotalsInfoBoxes';
import OemBulkActivationModal from './components/OemBulkActivationModal';
import { contentCells, deviceListFacets } from './config/algoliaTableConfig';
import { MODES } from './const/bulkProcess.const';

export default {
  name: 'DevicesView',
  components: {
    MuiAlgoliaList,
    MuiModal,
    TotalsInfoBoxes,
    OemBulkActivationModal,
  },

  data() {
    return {
      bulkMode: '',
      facetValues: {},
      isBulkActivationModalOpen: false,
      isInfoModalOpen: false,
      listTotalResults: 0,
      storeName: '',
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => get(state, 'operators.active.timezone') || TIME_ZONE.default,
      userUuid: state => state.user.data.uuid,
      features: state => get(state, 'features.data'),
    }),
  },
  created() {
    this.dropdownList = [
      {
        label: 'Activate devices',
        action: () => this.setBulkMode(MODES.activate),
      },
    ];
    this.infoModalConfig = {
      title: 'About this functionality',
      header: {
        class: 'pl-3',
        isClosable: true,
      },
    };
    this.deviceListFacets = deviceListFacets;
    this.CARRENTAL_FLAGS = CARRENTAL_FLAGS;
  },
  mounted() {
    // This is for the first time in the page to show the info modal.
    this.storeName = `DevicesView_isInfoModalShowed_${this.userUuid}`;
    const storedData = rehydrate.get(this.storeName);
    const isInfoModalShowed = storedData && get(storedData, this.userUuid);

    if (!isInfoModalShowed) {
      this.isInfoModalOpen = true;
      // We store in LS under the userUuid for one week
      rehydrate.set(this.storeName, this.userUuid, true, 168);
    }
  },
  methods: {
    showInfoModal() {
      this.isInfoModalOpen = true;
      // Refresh the time for LS stored value
      rehydrate.set(this.storeName, this.userUuid, true, 168);
    },
    setBulkMode(mode) {
      this.bulkMode = mode;
      this.isBulkActivationModalOpen = true;
    },
    onResultsChanged() {
      this.facetValues = {
        deviceInstallation: this.getFacetValues(this.$refs.devices, 'device_installation_status'),
        deviceData: this.getFacetValues(this.$refs.devices, 'device_data_status'),
      };
      this.listTotalResults = this.getStoreTotalResults(this.$refs.devices);
    },
    onBulkSuccess() {
      this.isBulkActivationModalOpen = false;
      this.refreshAlgoliaStore(this.$refs.devices, DELAY.short);
    },
    contentCells,
    getFacetValues,
    getStoreTotalResults,
    refreshAlgoliaStore,
  },
};
</script>

<template>
  <div
    class="DevicesView px-2 mt-3 px-lg-5"
    data-test-id="devices-view"
  >
    <div class="d-flex justify-content-between pb-2">
      <h1>Devices</h1>
      <div class="d-flex align-items-center">
        <div :class="{ 'pr-3': features[CARRENTAL_FLAGS.devicesBulkActivation] }">
          <ui-button
            :face="FACES.outline"
            :color="GRAYSCALE.groundLight"
            square
            data-test-id="show_info_modal-button"
            @clickbutton="showInfoModal"
          >
            <ui-icon
              :color="GRAYSCALE.inkLight"
              :size="ICONS_SIZES.small"
              :icon="ICONS.infoFull"
            />
          </ui-button>
        </div>
        <ui-dropdown
          v-if="features[CARRENTAL_FLAGS.devicesBulkActivation]"
          :color="COLORS.corporateSecondary"
          :text-color="GRAYSCALE.white"
          arrow
        >
          <p
            slot="trigger"
            class="pl-3 emobg-body-2"
          >
            OEM bulk activation
          </p>
          <ui-dropdown-actions
            slot="content"
            :actions.prop="dropdownList"
            data-test-id="dropdown-list"
          />
        </ui-dropdown>
      </div>
    </div>

    <TotalsInfoBoxes
      :facet-values="facetValues"
      :list-total-results="listTotalResults"
      data-test-id="totals_info_boxes"
      class="mb-5"
    />

    <MuiAlgoliaList
      ref="devices"
      :export-columns="contentCells({ operatorTimezone })"
      :facets="deviceListFacets"
      :table-config="contentCells({ operatorTimezone })"
      :no-data-label="FALLBACK_MESSAGE.dash"
      :is-broadcast-event-enabled="true"
      data-test-id="list"
      search-placeholder="Search by device ID, plate number or VIN"
      index="one_connected_fleet_device"
      @resultsChanged="onResultsChanged"
    />

    <OemBulkActivationModal
      :is-modal-visible="isBulkActivationModalOpen"
      :mode="bulkMode"
      data-test-id="oem_bulk_activation-modal"
      class="DevicesView__bulkActivationModal"
      @close="isBulkActivationModalOpen = false"
      @bulkSuccess="onBulkSuccess"
    />

    <MuiModal
      v-model="isInfoModalOpen"
      :size="SIZES.large"
      class="DevicesView__infoModal"
      data-test-id="info-modal"
      v-bind="infoModalConfig"
      @modal-closed="isInfoModalOpen = false"
    >
      <div
        slot="body"
        class="py-3"
      >
        <h2 class="emobg-color-ink pb-4">
          Some concepts to know about device stock monitoring
        </h2>
        <div class="d-flex emobg-font-small emobg-font-line-height-large emobg-color-ink">
          <div class="w-50">
            <p class="emobg-font-weight-semibold pb-3">
              OEMs service activations might take up to 72hs to be completed.
            </p>
            <p class="pb-3">
              Devices can show different installation status:
            </p>
            <p>
              <span class="emobg-font-weight-semibold">Installed</span>
              means the device is physically installed in the vehicle.
            </p>
            <p>
              <span class="emobg-font-weight-semibold">In progress</span>
              the installation is about to be activated and verified.
            </p>
            <p class="pb-3">
              <span class="emobg-font-weight-semibold">Uninstalled</span>
              means the device is still in the vehicle but no longer active.
            </p>
            <p class="pb-3">
              Devices can also show if they are sending data.
              This dimension is represented by the device data status and has different combinations:
            </p>
            <p>
              <span class="emobg-font-weight-semibold">In progress:</span>
              happens when connexus is checking if the device and plate are created in GWY.
            </p>
            <p>
              <span class="emobg-font-weight-semibold">Activated:</span>
              happens when connexus has established a connection and the device is transferring the data.
            </p>
            <p>
              <span class="emobg-font-weight-semibold">Deactivation:</span>
              the deactivation request is in progress.
            </p>
            <p>
              <span class="emobg-font-weight-semibold">Deactivated:</span>
              the deactivation request is completed and the data is not sent from the vehicle.
            </p>
            <p>
              <span class="emobg-font-weight-semibold">Terminated:</span>
              The device has been completely deactivated. It will not be able to be installed or activated again. This device won't send data again.
            </p>
            <p class="pb-5">
              <span class="emobg-font-weight-semibold">Error</span>
              and
              <span class="emobg-font-weight-semibold">rejected</span>
              happen when there is an issue with the device or with GWY. If this is happening, we recommend to contact ServiceNow.
            </p>
            <div class="d-flex align-items-center">
              <p class="emobg-font-weight-semibold mr-2">
                Find more helpful info on the icon
              </p>
              <ui-icon
                :color="GRAYSCALE.inkLight"
                :size="ICONS_SIZES.medium"
                :icon="ICONS.infoFull"
              />
            </div>
          </div>
          <div class="w-50">
            <img
              class="w-100"
              src="./images/info.png"
              alt="info"
            >
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="d-flex justify-content-end p-3"
      >
        <ui-button
          :color="COLORS.corporateSecondary"
          data-test-id="understood-button"
          @clickbutton="isInfoModalOpen = false"
        >
          Understood
        </ui-button>
      </div>
    </MuiModal>
  </div>
</template>

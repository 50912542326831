<script>
import keys from 'lodash/keys';
import find from 'lodash/find';
import toLower from 'lodash/toLower';
import kebabCase from 'lodash/kebabCase';

import { DEVICE_DATA_STATUSES, DEVICE_INSTALLATION_STATUSES } from '../const/deviceStatus.const';

export default {
  name: 'TotalsInfoBoxes',
  props: {
    facetValues: {
      type: Object,
      required: true,
    },
    listTotalResults: {
      type: Number,
      required: true,
    },
  },
  computed: {
    infoBoxes() {
      const installationStatusInfo = this.getStatusesInfo(DEVICE_INSTALLATION_STATUSES, 'deviceInstallation');
      const dataStatusInfo = this.getStatusesInfo(DEVICE_DATA_STATUSES, 'deviceData');

      return {
        installationStatusInfo,
        dataStatusInfo,
      };
    },
  },
  methods: {
    getStatusesInfo(statuses, facetName) {
      return keys(statuses).map(status => {
        const statusFacetValue = find(this.facetValues[facetName], value => toLower(value.name) === status);
        return {
          title: statuses[status],
          value: statusFacetValue ? statusFacetValue.count : '-',
        };
      });
    },
    kebabCase,
  },
};
</script>

<template>
  <div
    data-test-id="totals_info_boxes-component"
    class="emobg-background-color-white emobg-border-1 emobg-border-color-ground emobg-border-radius-small p-3"
  >
    <div class="row">
      <div
        data-test-id="device_installation_info"
        class="col-12 col-lg-5"
      >
        <div
          class="align-items-center emobg-background-color-ground-lighter
        emobg-border-1 emobg-border-color-ground-light emobg-border-radius-small row mx-0 p-2"
        >
          <div class="emobg-font-line-height-large col">
            <p class="emobg-font-weight-bold mb-1">
              Total devices
            </p>
            <p class="emobg-font-default">
              {{ listTotalResults }}
            </p>
          </div>
          <div
            v-for="infoBox in infoBoxes.installationStatusInfo"
            :key="kebabCase(infoBox.title)"
            class="col emobg-font-line-height-large"
          >
            <p class="emobg-font-weight-bold mb-1 ellipsis">
              {{ infoBox.title }}
            </p>
            <p class="emobg-font-default">
              {{ infoBox.value }}
            </p>
          </div>
        </div>
      </div>
      <div
        data-test-id="device_data_info"
        class="col-lg-7"
      >
        <div
          class="align-items-center emobg-background-color-ground-lighter
        emobg-border-1 emobg-border-color-ground-light emobg-border-radius-small row p-2 mx-0"
        >
          <div
            v-for="statusInfoBox in infoBoxes.dataStatusInfo"
            :key="kebabCase(statusInfoBox.title)"
            class="col emobg-font-line-height-large"
          >
            <p class="emobg-font-weight-bold mb-1 ellipsis">
              {{ statusInfoBox.title }}
            </p>
            <p class="emobg-font-default">
              {{ statusInfoBox.value }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

import concat from 'lodash/concat';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import camelCase from 'lodash/camelCase';
import { FILE_COLUMNS } from '../const/bulkFile.const';

export const readHeaders = sheetData => map(sheetData[0], camelCase);

export const hasIncorrectHeaders = headers => {
  // This is because difference lodash method inspects the first array to make difference.
  const headersDifference = concat(
    difference(headers, FILE_COLUMNS),
    difference(FILE_COLUMNS, headers),
  );
  return !isEmpty(headersDifference);
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "emobg-background-color-white emobg-border-1 emobg-border-color-ground emobg-border-radius-small p-3",
      attrs: { "data-test-id": "totals_info_boxes-component" },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "col-12 col-lg-5",
            attrs: { "data-test-id": "device_installation_info" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "align-items-center emobg-background-color-ground-lighter emobg-border-1 emobg-border-color-ground-light emobg-border-radius-small row mx-0 p-2",
              },
              [
                _c("div", { staticClass: "emobg-font-line-height-large col" }, [
                  _c("p", { staticClass: "emobg-font-weight-bold mb-1" }, [
                    _vm._v(" Total devices "),
                  ]),
                  _c("p", { staticClass: "emobg-font-default" }, [
                    _vm._v(" " + _vm._s(_vm.listTotalResults) + " "),
                  ]),
                ]),
                _vm._l(
                  _vm.infoBoxes.installationStatusInfo,
                  function (infoBox) {
                    return _c(
                      "div",
                      {
                        key: _vm.kebabCase(infoBox.title),
                        staticClass: "col emobg-font-line-height-large",
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "emobg-font-weight-bold mb-1 ellipsis",
                          },
                          [_vm._v(" " + _vm._s(infoBox.title) + " ")]
                        ),
                        _c("p", { staticClass: "emobg-font-default" }, [
                          _vm._v(" " + _vm._s(infoBox.value) + " "),
                        ]),
                      ]
                    )
                  }
                ),
              ],
              2
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "col-lg-7",
            attrs: { "data-test-id": "device_data_info" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "align-items-center emobg-background-color-ground-lighter emobg-border-1 emobg-border-color-ground-light emobg-border-radius-small row p-2 mx-0",
              },
              _vm._l(_vm.infoBoxes.dataStatusInfo, function (statusInfoBox) {
                return _c(
                  "div",
                  {
                    key: _vm.kebabCase(statusInfoBox.title),
                    staticClass: "col emobg-font-line-height-large",
                  },
                  [
                    _c(
                      "p",
                      { staticClass: "emobg-font-weight-bold mb-1 ellipsis" },
                      [_vm._v(" " + _vm._s(statusInfoBox.title) + " ")]
                    ),
                    _c("p", { staticClass: "emobg-font-default" }, [
                      _vm._v(" " + _vm._s(statusInfoBox.value) + " "),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
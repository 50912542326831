var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "emobg-font-weight-normal" },
    [
      _vm.status
        ? _c("ui-badge", { attrs: { color: _vm.badgeColor } }, [
            _vm._v(" " + _vm._s(_vm.sentenceCase(_vm.status)) + " "),
          ])
        : _c("span", [_vm._v(_vm._s(_vm.FALLBACK_MESSAGE.dash))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "DevicesView px-2 mt-3 px-lg-5",
      attrs: { "data-test-id": "devices-view" },
    },
    [
      _c("div", { staticClass: "d-flex justify-content-between pb-2" }, [
        _c("h1", [_vm._v("Devices")]),
        _c(
          "div",
          { staticClass: "d-flex align-items-center" },
          [
            _c(
              "div",
              {
                class: {
                  "pr-3":
                    _vm.features[_vm.CARRENTAL_FLAGS.devicesBulkActivation],
                },
              },
              [
                _c(
                  "ui-button",
                  {
                    attrs: {
                      face: _vm.FACES.outline,
                      color: _vm.GRAYSCALE.groundLight,
                      square: "",
                      "data-test-id": "show_info_modal-button",
                    },
                    on: { clickbutton: _vm.showInfoModal },
                  },
                  [
                    _c("ui-icon", {
                      attrs: {
                        color: _vm.GRAYSCALE.inkLight,
                        size: _vm.ICONS_SIZES.small,
                        icon: _vm.ICONS.infoFull,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.features[_vm.CARRENTAL_FLAGS.devicesBulkActivation]
              ? _c(
                  "ui-dropdown",
                  {
                    attrs: {
                      color: _vm.COLORS.corporateSecondary,
                      "text-color": _vm.GRAYSCALE.white,
                      arrow: "",
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "pl-3 emobg-body-2",
                        attrs: { slot: "trigger" },
                        slot: "trigger",
                      },
                      [_vm._v(" OEM bulk activation ")]
                    ),
                    _c("ui-dropdown-actions", {
                      attrs: {
                        slot: "content",
                        "data-test-id": "dropdown-list",
                      },
                      domProps: { actions: _vm.dropdownList },
                      slot: "content",
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("TotalsInfoBoxes", {
        staticClass: "mb-5",
        attrs: {
          "facet-values": _vm.facetValues,
          "list-total-results": _vm.listTotalResults,
          "data-test-id": "totals_info_boxes",
        },
      }),
      _c("MuiAlgoliaList", {
        ref: "devices",
        attrs: {
          "export-columns": _vm.contentCells({
            operatorTimezone: _vm.operatorTimezone,
          }),
          facets: _vm.deviceListFacets,
          "table-config": _vm.contentCells({
            operatorTimezone: _vm.operatorTimezone,
          }),
          "no-data-label": _vm.FALLBACK_MESSAGE.dash,
          "is-broadcast-event-enabled": true,
          "data-test-id": "list",
          "search-placeholder": "Search by device ID, plate number or VIN",
          index: "one_connected_fleet_device",
        },
        on: { resultsChanged: _vm.onResultsChanged },
      }),
      _c("OemBulkActivationModal", {
        staticClass: "DevicesView__bulkActivationModal",
        attrs: {
          "is-modal-visible": _vm.isBulkActivationModalOpen,
          mode: _vm.bulkMode,
          "data-test-id": "oem_bulk_activation-modal",
        },
        on: {
          close: function ($event) {
            _vm.isBulkActivationModalOpen = false
          },
          bulkSuccess: _vm.onBulkSuccess,
        },
      }),
      _c(
        "MuiModal",
        _vm._b(
          {
            staticClass: "DevicesView__infoModal",
            attrs: { size: _vm.SIZES.large, "data-test-id": "info-modal" },
            on: {
              "modal-closed": function ($event) {
                _vm.isInfoModalOpen = false
              },
            },
            model: {
              value: _vm.isInfoModalOpen,
              callback: function ($$v) {
                _vm.isInfoModalOpen = $$v
              },
              expression: "isInfoModalOpen",
            },
          },
          "MuiModal",
          _vm.infoModalConfig,
          false
        ),
        [
          _c(
            "div",
            { staticClass: "py-3", attrs: { slot: "body" }, slot: "body" },
            [
              _c("h2", { staticClass: "emobg-color-ink pb-4" }, [
                _vm._v(" Some concepts to know about device stock monitoring "),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex emobg-font-small emobg-font-line-height-large emobg-color-ink",
                },
                [
                  _c("div", { staticClass: "w-50" }, [
                    _c(
                      "p",
                      { staticClass: "emobg-font-weight-semibold pb-3" },
                      [
                        _vm._v(
                          " OEMs service activations might take up to 72hs to be completed. "
                        ),
                      ]
                    ),
                    _c("p", { staticClass: "pb-3" }, [
                      _vm._v(
                        " Devices can show different installation status: "
                      ),
                    ]),
                    _c("p", [
                      _c(
                        "span",
                        { staticClass: "emobg-font-weight-semibold" },
                        [_vm._v("Installed")]
                      ),
                      _vm._v(
                        " means the device is physically installed in the vehicle. "
                      ),
                    ]),
                    _c("p", [
                      _c(
                        "span",
                        { staticClass: "emobg-font-weight-semibold" },
                        [_vm._v("In progress")]
                      ),
                      _vm._v(
                        " the installation is about to be activated and verified. "
                      ),
                    ]),
                    _c("p", { staticClass: "pb-3" }, [
                      _c(
                        "span",
                        { staticClass: "emobg-font-weight-semibold" },
                        [_vm._v("Uninstalled")]
                      ),
                      _vm._v(
                        " means the device is still in the vehicle but no longer active. "
                      ),
                    ]),
                    _c("p", { staticClass: "pb-3" }, [
                      _vm._v(
                        " Devices can also show if they are sending data. This dimension is represented by the device data status and has different combinations: "
                      ),
                    ]),
                    _c("p", [
                      _c(
                        "span",
                        { staticClass: "emobg-font-weight-semibold" },
                        [_vm._v("In progress:")]
                      ),
                      _vm._v(
                        " happens when connexus is checking if the device and plate are created in GWY. "
                      ),
                    ]),
                    _c("p", [
                      _c(
                        "span",
                        { staticClass: "emobg-font-weight-semibold" },
                        [_vm._v("Activated:")]
                      ),
                      _vm._v(
                        " happens when connexus has established a connection and the device is transferring the data. "
                      ),
                    ]),
                    _c("p", [
                      _c(
                        "span",
                        { staticClass: "emobg-font-weight-semibold" },
                        [_vm._v("Deactivation:")]
                      ),
                      _vm._v(" the deactivation request is in progress. "),
                    ]),
                    _c("p", [
                      _c(
                        "span",
                        { staticClass: "emobg-font-weight-semibold" },
                        [_vm._v("Deactivated:")]
                      ),
                      _vm._v(
                        " the deactivation request is completed and the data is not sent from the vehicle. "
                      ),
                    ]),
                    _c("p", [
                      _c(
                        "span",
                        { staticClass: "emobg-font-weight-semibold" },
                        [_vm._v("Terminated:")]
                      ),
                      _vm._v(
                        " The device has been completely deactivated. It will not be able to be installed or activated again. This device won't send data again. "
                      ),
                    ]),
                    _c("p", { staticClass: "pb-5" }, [
                      _c(
                        "span",
                        { staticClass: "emobg-font-weight-semibold" },
                        [_vm._v("Error")]
                      ),
                      _vm._v(" and "),
                      _c(
                        "span",
                        { staticClass: "emobg-font-weight-semibold" },
                        [_vm._v("rejected")]
                      ),
                      _vm._v(
                        " happen when there is an issue with the device or with GWY. If this is happening, we recommend to contact ServiceNow. "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c(
                          "p",
                          { staticClass: "emobg-font-weight-semibold mr-2" },
                          [_vm._v(" Find more helpful info on the icon ")]
                        ),
                        _c("ui-icon", {
                          attrs: {
                            color: _vm.GRAYSCALE.inkLight,
                            size: _vm.ICONS_SIZES.medium,
                            icon: _vm.ICONS.infoFull,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "w-50" }, [
                    _c("img", {
                      staticClass: "w-100",
                      attrs: { src: require("./images/info.png"), alt: "info" },
                    }),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-end p-3",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "ui-button",
                {
                  attrs: {
                    color: _vm.COLORS.corporateSecondary,
                    "data-test-id": "understood-button",
                  },
                  on: {
                    clickbutton: function ($event) {
                      _vm.isInfoModalOpen = false
                    },
                  },
                },
                [_vm._v(" Understood ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }